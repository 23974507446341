import React from 'react';
import { Card } from 'antd';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import styles from './news-veranstaltungen.module.css';

export default ({ data: { news } }) => {
  return (
    <Layout>
      <div className={styles.root}>
        <h1>News & Veranstaltungen</h1>
        {news.edges.map(({ node: { header, date, content, mainImage } }) => {
          const { html } = content ? content.childMarkdownRemark : {};
          return (
            <Card key={header} className={styles.card} hoverable cover={mainImage && mainImage.file ? <img alt={header} src={mainImage.file.url} /> : null}>
              <Card.Meta
                title={
                  <h3>
                    {new Date(date).toLocaleDateString('de-DE', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })} | {header}
                  </h3>
                }
                description={
                  <div>
                    <div dangerouslySetInnerHTML={{ __html: html }} />
                  </div>
                }
              />
            </Card>
          );
        })}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query NewsVeranstaltungenPageQuery {
    news: allContentfulNeuigkeiten(sort: { fields: [date], order: DESC }, limit: 10) {
      edges {
        node {
          header
          date
          content {
            childMarkdownRemark {
              html
            }
          }
          mainImage {
            file {
              url
            }
          }
        }
      }
    }
  }
`;
